<template>
	<pui-datatable
		v-if="!isSuperAdmin"
		:modelName="modelName"
		:modelColumnDefs="columnDefs"
		:actions="actions"
		:externalFilter="externalFilter"
		:showDeleteBtn="true"
	></pui-datatable>
	<pui-datatable
		v-else
		:modelName="modelName"
		:modelColumnDefs="columnDefs"
		:actions="actions"
		:showDeleteBtn="true"
		:masterDetailColumnVisibles="masterDetailColumnVisibles"
	></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import ActionsActions from './PmActionsActions';

//import constants from '@/utils/constants';
import { isSuperAdmin } from '@/api/common';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'PmActionsGrid',
	data() {
		return {
			modelName: 'pmactions',
			actions: ActionsActions.actions,
			isSuperAdmin: false,
			columnDefs: {
				disabled: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				isexternal: {
					render: (data) => {
						if (data) {
							return this.$t('general.yes');
						} else {
							return this.$t('general.no');
						}
					}
				},
				datasourcetyperequired: {
					render: (data) => {
						if (data) {
							return this.$t('general.yes');
						} else {
							return this.$t('general.no');
						}
					}
				},
				iconroute: {
					createdCell: (td, cellData) => {
						td.style.padding = '6px 0px 0px 20px';
						td.style.width = '3rem';
						return (td.innerHTML = '<img src="' + this.iconPath(cellData) + '" style="max-width:1.5rem;max-height:1.5rem"></img>');
					}
				}
			},
			externalFilter: null,
			masterDetailColumnVisibles: {
				name: true,
				actioncode: true,
				actionmethod: true,
				authtype: true,
				actionurl: true,
				organizationname: true,
				isexternal: true,
				datasourcetyperequired: true,
				timeout: true,
				retrycount: true,
				disabled: true
			}
		};
	},
	computed: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		if (!this.isSuperAdmin) {
			this.externalFilter = {
				groupOp: 'or',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }],
				groups: []
			};
		}
	},
	methods: {
		iconPath(route) {
			var appProperties = this.$store.getters.getApplication;
			return appProperties.urlbase + route;
		}
	}
};
</script>

<style lang="postcss" scoped></style>
